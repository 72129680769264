.isPasswordVisibleor {
  position: relative;
  left: 360px;
  bottom: 30px;
  cursor: pointer;
}

.planActive {
  /* margin: 1.9rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.ownerOperatorPlanNames {
  margin-top: 14.5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.95rem;
  padding-left: 1rem;
}

.fleetPlanNames {
  margin-top: 15rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 1.2rem;
  padding-left: 0.5rem;
}
.driverPlanNames {
  margin-top: 17rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 0.95rem;
  padding-left: 1rem;
}

.membership-loading {
  height: 100vh;
  width: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileImg {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 10%;
}

.custom-dropdown-body {
  height: 250px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  border-radius: 8px;
  scrollbar-width: none;
  border: 1px solid var(--gray-100, #f2f4f7);
  background: var(--White, #fff);
}

.custom-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.custom-flex-new {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0rem;
}

.custom-gallery {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 50%;
}

.custom-tags_Slider {
  margin: 1rem;
  width: fit-content;
  border-radius: 10px;
}

.custom_tales_text {
  position: absolute;
  bottom: 0;
  background-color: rgba(15, 14, 14, 0.312);
  color: white;
  width: 98%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='range'] {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 10px;
}
input[type='range']:hover {
  opacity: 1;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 2px solid #054757;
  cursor: pointer; /* Cursor on hover */
}

/* start */
.my-button {
  position: relative;
  width: 80px;
  height: 33px;
}

.loader-custom {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px;
  height: 20px;
  border: 2px solid #3498db;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin-custom 1s linear infinite;
}

@keyframes spin-custom {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
/* end */

.custom-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.round-profileImg {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}
.round-profileImg-30 {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
}
.priceColor {
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  color: #005a70;
}
.forDays {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.couch_unsave_popup {
  background-color: #50535e;
  width: 500px;
  padding: 1rem;
  border-radius: 10px;
  position: fixed;
  top: 5%;
  left: 40%;
  z-index: 1;
}

.couchCalendar_time {
  background-color: #d6f4fc;
  border-radius: 10px;
  width: 91px;
  height: 25px;
  font-size: 0.6rem;
  border: #005a70;
}

.Ooprofile_vehicleButton {
  border: 1px solid var(--gray-300, #d0d5dd) !important;
  color: #344054;
  font-size: 14px;
  border-radius: 0;
  padding: 6px 10px;
}

.custom_active {
  background-color: #e5faff;
}
.membership-overviewButton {
  width: 200px;
  height: 40px;
  border: 1px solid#d0d5dd;
  font-weight: bold;
  border-radius: 5px;
}
.freightEvent {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: baseline;
  justify-content: space-between;
  height: 100%;
}
.applyBtn {
  background: #054757;
  border: 1px solid #054757;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 18px 10px 18px;
  opacity: 0.5;
}

.slick-arrow {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 20px;
  width: 20px;
}

/* Position the next arrow to the right */
.slick-next {
  margin-left: 1rem;
  right: auto;
  left: 100%;
}

/* Position the previous arrow to the left */
.slick-prev {
  right: 100%;
  left: auto;
}

.event-slider-image-size {
  min-width: 850px;
  height: 450px;
  object-fit: cover;
}
@media (max-width: 768px) {
  .event-slider-image-size {
    min-width: 600px;
    height: 400px;
    object-fit: cover;
  }
}
@media (max-width: 576px) {
  .event-slider-image-size {
    min-width: 500px;
    height: 400px;
    object-fit: cover;
  }
}

.star-rating {
  display: inline-block;
}

.star {
  cursor: pointer;
  font-size: 2rem;
  color: #d3d3d3;
}

.star.filled {
  /* width: 10px; */
  color: #ffd700;
}
.w-16 {
  width: 16% !important;
}

/* post card component */

.input-group-custom {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  align-items: stretch;
  width: 100%;
}
.coch-rqst:hover span path {
  stroke: #054757;
}
.jobSearch-main {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100vh;
}
.job-search {
  flex: 1;

  padding: 2rem;
}
.job-list {
  flex: 2;

  padding: 2rem;
  height: 100vh;
}
.job-heading-color {
  color: #054757;
}
.custom-gray {
  background: #e5faff !important;
  padding: 7px 10px;
  font-weight: 600;
}

button.btn.btn-outline-secondary:hover {
  background: none;
  color: #6c757d;
}

.driver-btn {
  border-radius: 45px;
  font-size: 13px;
  background: #E5FAFF;
  font-weight: 600;
  color: #08242B;
  padding: 3px 10px;
}

.us {
  position: absolute;
  top: 3px;
  left: 35px;
}

.new-2 {
  position: relative;
}

.new h6 {
  font-weight: 600;
  color: #000;
}
